import React from 'react';
import { useParams } from 'react-router-dom';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/Navbar';
import UserInfoForm from '../../Components/UserInfoForm';
import carDummyData from '../../fixtures/carsDummyData.json';
import './index.css';

export default function InfoPage({ ...props }) {
  const { id } = useParams();
  const data = carDummyData.find((e) => e.id === id);

  //   console.log(id);
  return (
    <div>
      <NavBar />
      <div className='info_page'>
        <div className='info_background'>
          <img src='/assets/imgs/bg_info.png' alt='bg_car' />
          <div className='bg_wrapper'></div>
        </div>
        <FeatureToast step={4} />
        {/* PaymentPage */}
        <UserInfoForm props={props} data={data} />
      </div>
      <Footer />
    </div>
  );
}
