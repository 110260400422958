import React, { useState } from 'react';
import usePagination from '../../../hooks/usePagination';
import Pagination from '@mui/material/Pagination';
import './index.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import FeaturesCard from './FeaturesCard';

export default function ProductDetail({ data }) {
  let [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [description_nav, setDescription_nav] = useState('description');
  const [isReadMore, setIsReadMore] = useState(true);
  const PER_PAGE = 1;
  const count = Math.ceil(data?.car_images.length / PER_PAGE);
  const _DATA = usePagination(data?.car_images, PER_PAGE);
  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  console.log(data);
  return (
    <div className='productDetail'>
      <div className='productDetail_hero_section'>
        <div className='img_section'>
          {_DATA.currentData().map((img) => (
            <img src={img?.image} alt={data?.img} />
          ))}
          <Pagination
            className='product_detail_carousel_pagination'
            shape='circular'
            count={count}
            page={page}
            onChange={handlePageChange}
          />
        </div>
        <div className='img_detail'>
          <div className='product_tags'>
            {/* {data?.tags.map((tag) => (
              <span className='product_tag'>{tag}</span>
            ))} */}
          </div>
          <div className='product_title'>{data?.title}</div>
          <div className='product_similar'>{data?.similarTo}</div>
          <div className='product_rating'>
            <span className='rating_starts'>
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z'
                  fill='#FFAF13'
                />
              </svg>
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z'
                  fill='#FFAF13'
                />
              </svg>
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z'
                  fill='#FFAF13'
                />
              </svg>
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z'
                  fill='#FFAF13'
                />
              </svg>
              <svg
                width='22'
                height='20'
                viewBox='0 0 22 20'
                fill='#373b4f'
                // stroke='#FFAF13'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z' />
              </svg>
            </span>
            ( {data?.review} ) Reviews
          </div>
          <div className='product_short_descp'>
            {data?.description.substring(0, 200)}...
          </div>
          <div className='date_row'>
            <div className='data_time'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  // shouldDisableDate={()}
                  label='Date'
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className='time_date_picker'
                      size='small'
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className='car_tags'>
              <span>
                <img src='/assets/imgs/automatic.png' alt='' />
                {data?.type}
              </span>
              <span>
                <img src='/assets/imgs/people.png' alt='' />
                {data?.noOfPeople} People
              </span>
              <span>
                <img src='/assets/imgs/bag.png' alt='' />
                {data?.noOfBags} Bags
              </span>
            </div>
          </div>

          <div className='product_buttons'>
            {/* <Link className='product_buy_now' to={`/payment/${data?.id}`}> */}
            <Link className='product_buy_now' to={`/info/${data?.id}`}>
              Select
            </Link>
            <div className='product_totalCost'>
              Total Cost: CAD {data?.totalCost}
            </div>
            <div className='product_dailyCost'>
              Daily Rate: CAD {data?.dailyRate}
            </div>
          </div>
        </div>
      </div>

      <div className='product_description'>
        <div className='product_description_navbar'>
          <span
            onClick={() => setDescription_nav('description')}
            style={{
              color: description_nav === 'description' ? 'white' : null,
              borderBottom:
                description_nav === 'description' ? '1px solid white' : null,
            }}
          >
            Description
          </span>
          <span
            onClick={() => setDescription_nav('features')}
            style={{
              color: description_nav === 'features' ? 'white' : null,
              borderBottom:
                description_nav === 'features' ? '1px solid white' : null,
            }}
          >
            Features
          </span>
          <span
            onClick={() => setDescription_nav('reviews')}
            style={{
              color: description_nav === 'reviews' ? 'white' : null,
              borderBottom:
                description_nav === 'reviews' ? '1px solid white' : null,
            }}
          >
            Reviews
          </span>
          <span
            onClick={() => setDescription_nav('locations')}
            style={{
              color: description_nav === 'locations' ? 'white' : null,
              borderBottom:
                description_nav === 'locations' ? '1px solid white' : null,
            }}
          >
            Locations
          </span>
        </div>
        <div className='product_description_main'>
          <div className='description_detail'>
            <div className='description'>
              {/* {data?.description} */}
              {isReadMore ? data?.description.slice(0, 500) : data?.description}
              <span
                onClick={() => setIsReadMore(!isReadMore)}
                className={`read_more ${isReadMore ? null : 'read_less'}`}
              >
                {isReadMore ? '...READ MORE' : 'SHOW LESS'}
                <svg width='8' viewBox='0 0 8 14' fill='none'>
                  <path
                    d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                    fill='#4FC8E9'
                  />
                </svg>
              </span>
            </div>
            <FeaturesCard features={data?.features} />
          </div>
          <div className='description_video'>
            <div className='description_video_heading'>Brand Video</div>
            <div className='description_video_main'>
              <img src='/assets/imgs/brand_video.png' alt='brand_video' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
