import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
// import {  } from "@mui/";
// import Search from ''
import './index.css';

export default function NavBar() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      //   bg='dark'
      variant='dark'
      className='nav_bar'
    >
      {/* <Container> */}
      <Navbar.Brand className='logo'>
        <img src='/assets/imgs/logo.png' alt='logo' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='me-auto'>
          <FormControl variant='standard'>
            {/* <InputLabel
              className='nav_search_label'
              htmlFor='input-with-icon-adornment'
            >
              Search
            </InputLabel> */}
            <Input
              placeholder='Search'
              className='nav_search'
              id='input-with-icon-adornment'
              endAdornment={
                <InputAdornment className='nav_search_svg' position='end'>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z'
                      stroke='#4FC8E9'
                      strokeWidth='1.83333'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M19.2497 19.25L15.2622 15.2625'
                      stroke='#4FC8E9'
                      strokeWidth='1.83333'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </InputAdornment>
              }
            />
          </FormControl>
        </Nav>
        <Nav className='nav_buttons'>
          <Link to='/'>Featured Cars</Link>
          <Link to='/'>About Us</Link>
          <Link to='/'>Contact Us</Link>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}
