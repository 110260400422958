import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion';

import { ReactComponent as Inventory } from '../../assets/inventory_nav.svg';
import { ReactComponent as Car } from '../../assets/car_nav.svg';
import { ReactComponent as Car2 } from '../../assets/car_2nav.svg';
import { ReactComponent as ManualCar2 } from '../../assets/manual_car2.svg';
import { ReactComponent as Bookings } from '../../assets/bookings2.svg';

// import {  } from "@mui/";
// import Search from ''
import './index.css';

export default function NavBar() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      //   bg='dark'
      variant='dark'
      className='mobile_nav_bar'
    >
      {/* <Container> */}
      <Navbar.Brand className='logo'>
        <img src='/assets/imgs/logo.png' alt='logo' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='nav_buttons'>
          <div className='sidebar_links'>
            <Link to='/bookings' className={`sidebar_link `}>
              <Bookings />
              My Bookings
            </Link>
            <Link to='/view_bookings' className={`sidebar_link `}>
              <Bookings />
              View Bookings
            </Link>
            <Link to='/payment' className={`sidebar_link `}>
              <Bookings />
              Payments
            </Link>
          </div>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}
