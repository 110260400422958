import React, { useEffect, useState } from 'react';
import CarCard from '../../CardsWrapper/CarCard';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './index.css';

import { get_all_cars } from '../../../API';

export default function FeaturedVehicles({ fromDate, untilDate }) {
  const FeaturedVehiclesData = [
    {
      id: 'feat_car_1',
      img: 'car_1',
      title: 'Standard SUV',
      similarTo: 'Ford Edge or Similar',
      type: 'Automatic',
      noOfPeople: 5,
      noOfBags: 5,
      dailyRate: 45.0,
      totalCost: 698.0,
      cardPath: '#',
      selectPath: '#',
    },
    {
      id: 'feat_car_2',
      img: 'car_2',
      title: 'Standard SUV',
      similarTo: 'Ford Edge or Similar',
      type: 'Automatic',
      noOfPeople: 5,
      noOfBags: 5,
      dailyRate: 45.0,
      totalCost: 698.0,
      cardPath: '#',
      selectPath: '#',
    },
    {
      id: 'feat_car_3',
      img: 'car_3',
      title: 'Intermediate',
      similarTo: 'Nissan Versa or Similar',
      type: 'Automatic',
      noOfPeople: 5,
      noOfBags: 5,
      dailyRate: 45.0,
      totalCost: 698.0,
      cardPath: '#',
      selectPath: '#',
    },
  ];

  const [allCars, setAllCars] = useState(null);

  useEffect(() => {
    get_all_cars().then((res) => {
      if (res.status === 200 || res.status === 304) {
        setAllCars([res.data[0], res.data[1], res.data[2]]);
      } else {
        setAllCars([]);
      }
    });
  }, []);
  // console.log(allCars);

  return (
    <div className='featured_vehicles'>
      <div className='heading'>Featured Vehicles</div>
      <div className='featured_card_wrapper'>
        {allCars ? (
          allCars.map((car, index) => (
            <CarCard
              id={car.id}
              key={car.id}
              img={car.car_images[0]?.image}
              title={car.title}
              similarTo={''}
              type={car.type}
              noOfPeople={car.number_of_seats}
              noOfBags={car.number_of_bags}
              dailyRate={car.daily_price}
              totalCost={car.total_price}
              cardPath={`/car/${car.id}`}
              selectPath={`/info/${car.id}`}
              fromDate={fromDate}
              untilDate={untilDate}
            />
          ))
        ) : (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}

        {/* {FeaturedVehiclesData.map((car, index) => (
          <CarCard
            key={index}
            img={car.img}
            title={car.title}
            similarTo={car.similarTo}
            type={car.type}
            noOfPeople={car.noOfPeople}
            noOfBags={car.noOfBags}
            dailyRate={car.dailyRate}
            totalCost={car.totalCost}
            cardPath={`/car/${car.id}`}
            selectPath={`/info/${car.id}`}
          />
        ))} */}
      </div>
    </div>
  );
}
