import React from 'react';
import Navbar from '../../Components/Navbar';
import MobileNavbar from '../../Components/MobileNavbar';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import './index.css';
import CarsWrapper from '../../Components/CarsWrapper';

export default function Bookings() {
  return (
    <div className='dashboard page'>
      <Sidebar page='bookings' />
      <div className='hero_content'>
        {/* <MobileNavbar /> */}
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>My Bookings</h4>
            </div>
            <div className='booking'>
              <CarsWrapper per_page={2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
