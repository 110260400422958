import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './index.css';
export default function TimingsCard({ ...prop }) {
  const props = prop.props;
  let navigate = useNavigate();

  return (
    <div className='timingsCard_wrapper'>
      <form
        className='timingsCard'
        onSubmit={(e) => {
          e.preventDefault();
          navigate('search');
        }}
      >
        <div className='fieldsWrapper'>
          <div className='destination_select'>
            <div>Where</div>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel id='airport_name'>Enter Airport Name</InputLabel>
              <Select
                required
                className='airport_name'
                labelId='airport_name'
                id='demo-simple-select'
                value={props.airportName}
                label='Enter Airport Name'
                onChange={(e) => props.setAirportName(e.target.value)}
              >
                <MenuItem
                  className='airport_name_menu_item'
                  value={'YZD - Downsview Airport'}
                >
                  <svg
                    style={{ marginRight: '10px' }}
                    width='14'
                    viewBox='0 0 14 17'
                    fill='none'
                  >
                    <path
                      d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                      fill='white'
                    />
                  </svg>
                  YZD - Downsview Airport
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'YKZ - Toronto Buttonville Municipal Airport'}
                >
                  <svg
                    style={{ marginRight: '10px' }}
                    width='14'
                    viewBox='0 0 14 17'
                    fill='none'
                  >
                    <path
                      d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                      fill='white'
                    />
                  </svg>
                  YKZ - Toronto Buttonville Municipal Airport
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'YYZ - Toronto Pearson Interbation Airport'}
                >
                  <svg
                    style={{ marginRight: '10px' }}
                    width='14'
                    viewBox='0 0 14 17'
                    fill='none'
                  >
                    <path
                      d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                      fill='white'
                    />
                  </svg>
                  YYZ - Toronto Pearson Interbation Airport
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'Toronto ON, Canada'}
                >
                  <svg
                    style={{ marginRight: '10px' }}
                    width='14'
                    viewBox='0 0 14 17'
                    fill='none'
                  >
                    <path
                      d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                      fill='white'
                    />
                  </svg>
                  Toronto ON, Canada
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'YTZ - Billy Bishop Toronto CIty Airport'}
                >
                  <svg
                    style={{ marginRight: '10px' }}
                    width='14'
                    viewBox='0 0 14 17'
                    fill='none'
                  >
                    <path
                      d='M7 16.796L2.227 12.023C1.28301 11.079 0.64014 9.87626 0.379696 8.56689C0.119253 7.25752 0.25293 5.90032 0.763824 4.66693C1.27472 3.43353 2.13988 2.37933 3.24991 1.63764C4.35994 0.895939 5.66498 0.500061 7 0.500061C8.33502 0.500061 9.64006 0.895939 10.7501 1.63764C11.8601 2.37933 12.7253 3.43353 13.2362 4.66693C13.7471 5.90032 13.8808 7.25752 13.6203 8.56689C13.3599 9.87626 12.717 11.079 11.773 12.023L7 16.796ZM10.7125 10.9625C11.4467 10.2283 11.9466 9.29279 12.1492 8.27441C12.3517 7.25602 12.2477 6.20045 11.8503 5.24117C11.4529 4.28189 10.78 3.46198 9.91669 2.88513C9.05334 2.30827 8.03833 2.00038 7 2.00038C5.96167 2.00038 4.94666 2.30827 4.08331 2.88513C3.21997 3.46198 2.54706 4.28189 2.14969 5.24117C1.75231 6.20045 1.64831 7.25602 1.85084 8.27441C2.05337 9.29279 2.55333 10.2283 3.2875 10.9625L7 14.675L10.7125 10.9625ZM7 8.75C6.60218 8.75 6.22065 8.59197 5.93934 8.31066C5.65804 8.02936 5.5 7.64783 5.5 7.25C5.5 6.85218 5.65804 6.47065 5.93934 6.18934C6.22065 5.90804 6.60218 5.75 7 5.75C7.39783 5.75 7.77936 5.90804 8.06066 6.18934C8.34197 6.47065 8.5 6.85218 8.5 7.25C8.5 7.64783 8.34197 8.02936 8.06066 8.31066C7.77936 8.59197 7.39783 8.75 7 8.75Z'
                      fill='white'
                    />
                  </svg>
                  YTZ - Billy Bishop Toronto CIty Airport
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <div>From</div>
            <div className='date_time'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // shouldDisableDate={(23, 24, 25) => true}
                  disablePast
                  label='Date'
                  value={props.fromDate}
                  onChange={(newValue) => {
                    props.setFromDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className='time_date_picker'
                      size='small'
                      required
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                  <InputLabel id='from_time'>From time</InputLabel>
                  <Select
                    className='airport_name'
                    required
                    labelId='from_time'
                    id='from-time-select'
                    value={props.fromTime}
                    label='From time'
                    onChange={(e) => props.setFromTime(e.target.value)}
                  >
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'00:30'}
                    >
                      {' '}
                      12:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:00'}
                    >
                      {' '}
                      01:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:30'}
                    >
                      {' '}
                      01:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:00'}
                    >
                      {' '}
                      02:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:30'}
                    >
                      {' '}
                      02:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:00'}
                    >
                      {' '}
                      03:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:30'}
                    >
                      {' '}
                      03:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:00'}
                    >
                      {' '}
                      04:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:30'}
                    >
                      {' '}
                      04:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:00'}
                    >
                      {' '}
                      05:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:30'}
                    >
                      {' '}
                      05:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:00'}
                    >
                      {' '}
                      06:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:30'}
                    >
                      {' '}
                      06:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:00'}
                    >
                      {' '}
                      07:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:30'}
                    >
                      {' '}
                      07:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:00'}
                    >
                      {' '}
                      08:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:30'}
                    >
                      {' '}
                      08:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:00'}
                    >
                      {' '}
                      09:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:30'}
                    >
                      {' '}
                      09:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:00'}
                    >
                      {' '}
                      10:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:30'}
                    >
                      {' '}
                      10:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:00'}
                    >
                      {' '}
                      11:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:30'}
                    >
                      {' '}
                      11:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:00'}
                    >
                      {' '}
                      12:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:30'}
                    >
                      {' '}
                      12:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:00'}
                    >
                      {' '}
                      01:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:30'}
                    >
                      {' '}
                      01:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:00'}
                    >
                      {' '}
                      02:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:30'}
                    >
                      {' '}
                      02:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:00'}
                    >
                      {' '}
                      03:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:30'}
                    >
                      {' '}
                      03:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:00'}
                    >
                      {' '}
                      04:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:30'}
                    >
                      {' '}
                      04:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:00'}
                    >
                      {' '}
                      05:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:30'}
                    >
                      {' '}
                      05:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:00'}
                    >
                      {' '}
                      06:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:30'}
                    >
                      {' '}
                      06:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:00'}
                    >
                      {' '}
                      07:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:30'}
                    >
                      {' '}
                      07:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:00'}
                    >
                      {' '}
                      08:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:30'}
                    >
                      {' '}
                      08:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:00'}
                    >
                      {' '}
                      09:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:30'}
                    >
                      {' '}
                      09:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:00'}
                    >
                      {' '}
                      10:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:30'}
                    >
                      {' '}
                      10:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:00'}
                    >
                      {' '}
                      11:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:30'}
                    >
                      {' '}
                      11:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'24:00'}
                    >
                      {' '}
                      12:00 pm{' '}
                    </MenuItem>
                  </Select>
                </FormControl>
              </LocalizationProvider>
            </div>
          </div>
          <div>
            <div>Until</div>
            <div className='date_time'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  label='Date'
                  value={props.untilDate}
                  onChange={(newValue) => {
                    props.setUntilDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      size='small'
                      className='time_date_picker'
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                  <InputLabel id='until_time'>Until time</InputLabel>
                  <Select
                    className='airport_name'
                    labelId='until_time'
                    id='until-time-select'
                    required
                    value={props.untilTime}
                    label='Until time'
                    onChange={(e) => props.setUntilTime(e.target.value)}
                  >
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'00:30'}
                    >
                      {' '}
                      12:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:00'}
                    >
                      {' '}
                      01:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'01:30'}
                    >
                      {' '}
                      01:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:00'}
                    >
                      {' '}
                      02:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'02:30'}
                    >
                      {' '}
                      02:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:00'}
                    >
                      {' '}
                      03:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'03:30'}
                    >
                      {' '}
                      03:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:00'}
                    >
                      {' '}
                      04:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'04:30'}
                    >
                      {' '}
                      04:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:00'}
                    >
                      {' '}
                      05:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'05:30'}
                    >
                      {' '}
                      05:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:00'}
                    >
                      {' '}
                      06:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'06:30'}
                    >
                      {' '}
                      06:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:00'}
                    >
                      {' '}
                      07:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'07:30'}
                    >
                      {' '}
                      07:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:00'}
                    >
                      {' '}
                      08:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'08:30'}
                    >
                      {' '}
                      08:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:00'}
                    >
                      {' '}
                      09:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'09:30'}
                    >
                      {' '}
                      09:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:00'}
                    >
                      {' '}
                      10:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'10:30'}
                    >
                      {' '}
                      10:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:00'}
                    >
                      {' '}
                      11:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'11:30'}
                    >
                      {' '}
                      11:30 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:00'}
                    >
                      {' '}
                      12:00 am{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'12:30'}
                    >
                      {' '}
                      12:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:00'}
                    >
                      {' '}
                      01:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'13:30'}
                    >
                      {' '}
                      01:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:00'}
                    >
                      {' '}
                      02:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'14:30'}
                    >
                      {' '}
                      02:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:00'}
                    >
                      {' '}
                      03:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'15:30'}
                    >
                      {' '}
                      03:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:00'}
                    >
                      {' '}
                      04:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'16:30'}
                    >
                      {' '}
                      04:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:00'}
                    >
                      {' '}
                      05:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'17:30'}
                    >
                      {' '}
                      05:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:00'}
                    >
                      {' '}
                      06:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'18:30'}
                    >
                      {' '}
                      06:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:00'}
                    >
                      {' '}
                      07:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'19:30'}
                    >
                      {' '}
                      07:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:00'}
                    >
                      {' '}
                      08:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'20:30'}
                    >
                      {' '}
                      08:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:00'}
                    >
                      {' '}
                      09:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'21:30'}
                    >
                      {' '}
                      09:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:00'}
                    >
                      {' '}
                      10:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'22:30'}
                    >
                      {' '}
                      10:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:00'}
                    >
                      {' '}
                      11:00 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'23:30'}
                    >
                      {' '}
                      11:30 pm{' '}
                    </MenuItem>
                    <MenuItem
                      className='airport_name_menu_item'
                      value={'24:00'}
                    >
                      {' '}
                      12:00 pm{' '}
                    </MenuItem>
                  </Select>
                </FormControl>
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div>
          <button className='timingsCard_submit' type='submit'>
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
