import React, { useEffect, useState } from 'react';
import './index.css';
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/Navbar';
import ProductDetail from '../../Components/ProductDetailWrapper/ProductDetail';
import CarCarousel from '../../Components/ProductDetailWrapper/CarCarousel';

import carDummyData from '../../fixtures/carsDummyData.json';

import { get_car_by_id } from '../../API';

export default function ProductDetailPage({ fromDate, untilDate }) {
  const { id } = useParams();
  const data = carDummyData.find((e) => e.id === id);

  const [carData, setCarData] = useState(null);

  useEffect(() => {
    get_car_by_id(id).then((res) => {
      if (res.status === 200 || res.status === 304) {
        console.log(res.data);
        setCarData(res.data);
      } else {
        setCarData([]);
      }
    });
  }, []);
  // console.log(carData);

  return (
    <div>
      <NavBar />
      <div className='product_detail_page'>
        {carData && <ProductDetail data={carData} />}
        <CarCarousel untilDate={untilDate} fromDate={fromDate} />
      </div>
      <Footer />
    </div>
  );
}
