import React, { useEffect, useState } from 'react';
import CarCard from '../../CardsWrapper/CarCard';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../../hooks/usePagination';
import carDummyData from '../../../fixtures/carsDummyData.json';
import './index.css';

import { get_all_cars } from '../../../API';

export default function ChooseVehicle({ fromDate, untilDate }) {
  let [page, setPage] = useState(1);
  const PER_PAGE = 6;

  const [allCars, setAllCars] = useState([]);

  useEffect(() => {
    get_all_cars().then((res) => {
      if (res.status === 200 || res.status === 304) {
        setAllCars(res.data);
      } else {
        setAllCars([]);
      }
    });
  }, []);

  console.log(allCars);

  const count = Math.ceil(allCars?.length / PER_PAGE);
  const _DATA = usePagination(allCars, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className='featured_vehicles'>
      <div className='heading'>Choose A Vehicle Class</div>
      <div className='featured_card_wrapper'>
        {_DATA.currentData().map((car, index) => (
          <CarCard
            id={car.id}
            key={car.id}
            img={car.car_images[0]?.image}
            title={car.title}
            similarTo={''}
            type={car.type}
            noOfPeople={car.number_of_seats}
            noOfBags={car.number_of_bags}
            dailyRate={car.daily_price}
            totalCost={car.total_price}
            cardPath={`/car/${car.id}`}
            selectPath={`/info/${car.id}`}
            fromDate={fromDate}
            untilDate={untilDate}
          />
        ))}
      </div>
      <Pagination
        className='chooseVehicle_pagination'
        // variant='outlined'
        shape='circular'
        count={count}
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
}
