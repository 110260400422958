import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import './index.css';

export default function CarFullCard({ ...props }) {
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const [mileage, setMileage] = useState(null);
  const [vehicleType, setVehicleType] = useState(null);
  const [noOfPass, setNoOfPass] = useState(null);
  const [model, setModel] = useState(null);
  const [carType, setCarType] = useState(null);
  const [size, setSize] = useState(null);

  const EditModal = () => {
    const handleSave = (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append('File', selectedFile);
      // console.log(formData);
    };
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='editModal'>
            <div className='editModal_header'>
              <h5>Edit Vehicle</h5>
              <div onClick={() => onClose()} className='editModal_close'>
                <svg
                  width='25'
                  height='25'
                  viewBox='0 0 110 110'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
            <form
              action='#'
              onSubmit={(e) => handleSave(e)}
              className='card_details'
            >
              <div className='info_wrapper'>
                <div className='info_row '>
                  <div className='field'>
                    Mileage
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>Select Mileage</InputLabel>
                      <Select
                        className='airport_name'
                        labelId='mileage'
                        id='mileage'
                        defaultValue={mileage}
                        label='Enter Airline Name'
                        onChange={(e) => setMileage(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={10}>
                          Mileage 1
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={20}>
                          Mileage 2
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Mileage 3
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={40}>
                          Mileage 4
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={50}>
                          Mileage 5
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Vehicle Type
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Vehicle Type
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelId='vehicleType'
                        id='demo-simple-select'
                        defaultValue={vehicleType}
                        label='Vehicle Type'
                        onChange={(e) => setVehicleType(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={10}>
                          Vehicle Type 1
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={20}>
                          Vehicle Type 2
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Vehicle Type 3
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Vehicle Type 4
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Vehicle Type 5
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Number of Passengers
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>
                        Select Number of Passengers
                      </InputLabel>
                      <Select
                        className='airport_name'
                        labelId='numberOfPassengers'
                        id='demo-simple-select'
                        defaultValue={noOfPass}
                        label='Number of Passengers'
                        onChange={(e) => setNoOfPass(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={10}>
                          1
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={20}>
                          2
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          3
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          4
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          5
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Model
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>Select Model</InputLabel>
                      <Select
                        className='airport_name'
                        labelId='Model'
                        id='demo-simple-select'
                        defaultValue={model}
                        label='Model'
                        onChange={(e) => setModel(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={10}>
                          Model 1
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={20}>
                          Model 2
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Model 3
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Model 4
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Model 5
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Car Type
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>Select Car Type</InputLabel>
                      <Select
                        className='airport_name'
                        labelId='carType'
                        id='demo-simple-select'
                        defaultValue={carType}
                        label='Car Type'
                        onChange={(e) => setCarType(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={10}>
                          Car Type 1
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={20}>
                          Car Type 2
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Car Type 3
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Car Type 4
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Car Type 5
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='field'>
                    Size
                    <FormControl
                      className='edit_dropdown'
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id='airport_name'>Select Size</InputLabel>
                      <Select
                        className='airport_name'
                        labelId='size'
                        id='demo-simple-select'
                        defaultValue={size}
                        label='Size'
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <MenuItem className='airport_name_menu_item' value={10}>
                          Size 1
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={20}>
                          Size 2
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Size 3
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Size 4
                        </MenuItem>
                        <MenuItem className='airport_name_menu_item' value={30}>
                          Size 5
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='info_row '>
                  <div className='field'>
                    Add Car Images
                    <Button
                      required
                      className='user_upload_btn edit_dropdown'
                      variant='contained'
                      component='label'
                    >
                      <svg
                        width='36'
                        height='36'
                        viewBox='0 0 36 36'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                          fill='#4FC8E9'
                        />
                        <path
                          d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                          fill='#4FC8E9'
                        />
                      </svg>
                      {selectedFile ? selectedFile.name : 'Upload Car Images'}
                      <input
                        required
                        type='file'
                        name='UserDriverLicense'
                        id='UserDriverLicense'
                        hidden
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <div className='confirmation_btn'>
                <button type='submit'> Save </button>
              </div>
            </form>
          </div>
        );
      },
    });
  };

  return (
    <Link to={'#'} key={props?.key} className='carFullCard_wrapper'>
      <div className='carCard_body'>
        <div className='carCard_hero_img'>
          <img src={`${props?.img}`} alt='car_1' />
        </div>
        <div className='carCard_detail'>
          <div className='carCard_title'>{props?.title}</div>
          <div className='car_details'>
            <div className='car_similar_to'>{props?.similarTo}</div>
            <div className='price_details'>
              Features & Price Details
              <svg
                width='8'
                viewBox='0 0 8 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                  fill='#4FC8E9'
                />
              </svg>
            </div>
          </div>
          <div className='car_tags'>
            <span>
              <img src='/assets/imgs/automatic.png' alt='' />
              {props?.type}
            </span>
            <span>
              <img src='/assets/imgs/people.png' alt='' />
              {props?.noOfPeople} People
            </span>
            <span>
              <img src='/assets/imgs/bag.png' alt='' />
              {props?.noOfBags} Bags
            </span>
          </div>

          <div className='car_costs'>
            <div className='total_cost'>
              Total Cost: CAD {props?.totalCost}.00
            </div>
            <div className='daily_rate'>
              Daily Rate: CAD {props?.dailyRate}.00
            </div>
          </div>
        </div>
      </div>
      <div className='card_actions'>
        <div className='car_costs'>
          <div className='daily_rate daily_rate_action'>
            Daily Rate: CAD {props?.dailyRate}.00
          </div>
        </div>

        <div className='car_select_btn'>
          <button
            onClick={(e) => {
              e.preventDefault();
              EditModal();
            }}
            className='primary'
            type='submit'
          >
            Edit
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setDeleteModal(true);
            }}
            className='danger'
            type='submit'
          >
            Cancel Booking
          </button>
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby='conf_modal'
            aria-describedby='modal-modal-description'
          >
            <div className='confModal'>
              <svg
                width='110'
                height='110'
                viewBox='0 0 110 110'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                  stroke='#FF2552'
                  strokeWidth='11.625'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{' '}
              <br />
              <h4>Are You Sure?</h4>
              <p>Do you really want to Cancel the booking</p>
              <div className='btns'>
                <button
                  onClick={() => setDeleteModal(false)}
                  className='secondary'
                >
                  No
                </button>
                <button className='primary'>Yes</button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Link>
  );
}
