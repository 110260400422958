import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../hooks/usePagination';
import carDummyData from '../../fixtures/carsDummyData.json';
import './index.css';
import CarFullCard from '../CarFullCard';

export default function CarsWrapper({ per_page }) {
  let [page, setPage] = useState(1);
  const PER_PAGE = per_page;

  const count = Math.ceil(carDummyData.length / PER_PAGE);
  const _DATA = usePagination(carDummyData, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <div>
      <div className='cars_wrapper'>
        {_DATA.currentData().map((car, index) => (
          <CarFullCard
            key={index}
            img={car.img}
            title={car.title}
            similarTo={car.similarTo}
            type={car.type}
            noOfPeople={car.noOfPeople}
            noOfBags={car.noOfBags}
            dailyRate={car.dailyRate}
            totalCost={car.totalCost}
            cardPath={`/car/${car.id}`}
            selectPath={`/info/${car.id}`}
          />
        ))}
      </div>
      {/* <Pagination
        className='cars_page_pagination'
        // variant='outlined'
        shape='circular'
        count={count}
        page={page}
        onChange={handlePageChange}
      /> */}
    </div>
  );
}
