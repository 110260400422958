import React, { useState } from 'react';
import MobileNavbar from '../../Components/MobileNavbar';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import './index.css';

import { Button } from '@mui/material';

export default function Settings({ data }) {
  const [drivingLicenseFile, setDrivingLicenseFile] = useState();
  const [insuranseFile, setInsuranseFile] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const couponsData = [
    {
      id: '01',
      invoice: 'InvoicNamee',
      amount: '$564',
      date: 'FFR23EW',
      status: 'Deactive',
    },
    {
      id: '02',
      invoice: 'InvoicNamee',
      amount: '$64',
      date: 'FFR23EW',
      status: 'Deactive',
    },
    {
      id: '03',
      invoice: 'InvoicNamee',
      amount: '$500',
      date: 'FFR23EW',
      status: 'Deactive',
    },
    {
      id: '04',
      invoice: 'InvoicNamee',
      amount: '$264',
      date: 'FFR23EW',
      status: 'Deactive',
    },
    {
      id: '05',
      invoice: 'InvoicNamee',
      amount: '$164',
      date: 'FFR23EW',
      status: 'Deactive',
    },
    {
      id: '06',
      invoice: 'InvoicNamee',
      amount: '$264',
      date: 'FFR23EW',
      status: 'Deactive',
    },
  ];

  return (
    <div className='dashboard page settings_page'>
      <Sidebar page='settings' />
      <div className='hero_content'>
        {/* <MobileNavbar /> */}
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              <h4>Settings</h4>
              <button>Log Out</button>
            </div>
            <div className='settings_body'>
              <div className='persoal_info'>
                <h6>Personal Info</h6>
                <div className='persoal_info_row'>
                  <input
                    type='text'
                    id='firstName'
                    name='firstName'
                    placeholder='Enter First Name'
                  />
                  <input
                    type='text'
                    id='lastName'
                    name='lastName'
                    placeholder='Enter Last Name'
                  />
                </div>
                <div className='persoal_info_row'>
                  <input
                    type='text'
                    id='email'
                    name='email'
                    placeholder='Enter Email Address'
                  />
                  <input
                    type='text'
                    id='phoneNumber'
                    name='phoneNumber'
                    placeholder='Phone Number'
                  />
                </div>
              </div>

              <div className='body_row'>
                <div className='uploadBtn '>
                  <Button
                    required
                    className='user_upload_btn'
                    variant='contained'
                    component='label'
                  >
                    <svg
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                        fill='white'
                      />
                      <path
                        d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                        fill='white'
                      />
                    </svg>
                    {drivingLicenseFile
                      ? drivingLicenseFile.name
                      : 'Uploaded License'}
                    <input
                      required
                      type='file'
                      name='UserDriverLicense'
                      id='UserDriverLicense'
                      hidden
                      onChange={(e) => setDrivingLicenseFile(e.target.files[0])}
                    />
                  </Button>
                </div>
                <div className='uploadBtn '>
                  <Button
                    required
                    className='user_upload_btn'
                    variant='contained'
                    component='label'
                  >
                    <svg
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                        fill='white'
                      />
                      <path
                        d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                        fill='white'
                      />
                    </svg>
                    {insuranseFile ? insuranseFile.name : 'Uploaded Insurance'}
                    <input
                      required
                      type='file'
                      name='UserDriverLicense'
                      id='UserDriverLicense'
                      hidden
                      onChange={(e) => setInsuranseFile(e.target.files[0])}
                    />
                  </Button>
                </div>
              </div>

              <div className='body_row'>
                <div className='payment_info'>
                  <h6>Payment Info</h6>

                  <div className='payment_cards'>
                    <button className='payment_card'>
                      <svg
                        width='89'
                        height='55'
                        viewBox='0 0 89 55'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M32.4648 5.88086H56.5272V49.1169H32.4648V5.88086Z'
                          fill='#FF5F00'
                        />
                        <path
                          d='M33.9932 27.5C33.9932 18.7154 38.1181 10.9236 44.4581 5.8818C39.7988 2.2152 33.917 0 27.5003 0C12.2984 0 0 12.2984 0 27.5C0 42.7012 12.2984 55 27.5 55C33.9166 55 39.7984 52.7848 44.4581 49.1179C38.1181 44.1526 33.9932 36.2846 33.9932 27.5Z'
                          fill='#EB001B'
                        />
                        <path
                          d='M88.9913 27.5C88.9913 42.7012 76.6929 55 61.4913 55C55.0747 55 49.1929 52.7848 44.5332 49.1179C50.9498 44.0764 54.9985 36.2846 54.9985 27.5C54.9985 18.7154 50.8733 10.9236 44.5332 5.8818C49.1926 2.2152 55.0747 0 61.4913 0C76.6929 0 88.9917 12.375 88.9917 27.5H88.9913Z'
                          fill='#F79E1B'
                        />
                      </svg>
                      Master Card *********4556
                    </button>
                    <button className='payment_card'>
                      <svg
                        width='50'
                        height='60'
                        viewBox='0 0 50 60'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M42.4147 4.67026C39.6932 1.51489 34.7736 0.162109 28.4801 0.162109H10.2145C9.59178 0.162133 8.98952 0.388111 8.51597 0.799415C8.04243 1.21072 7.72865 1.78037 7.63106 2.40595L0.0255957 51.4722C-0.125576 52.4398 0.610947 53.3159 1.57501 53.3159H12.8516L15.6836 35.0436L15.5957 35.6158C15.7975 34.3242 16.8834 33.3715 18.1693 33.3715H23.5279C34.0549 33.3715 42.2977 29.0221 44.7055 16.4402C44.777 16.0681 44.8389 15.7059 44.8924 15.352C44.5885 15.1883 44.5885 15.1883 44.8924 15.352C45.6094 10.7016 44.8875 7.53615 42.4147 4.67026Z'
                          fill='#27346A'
                        />
                        <path
                          d='M19.9984 13.6768C20.3067 13.5275 20.6439 13.4501 20.9854 13.4503H35.3053C37.001 13.4503 38.5826 13.5626 40.0279 13.7992C40.4324 13.8648 40.8347 13.9431 41.2344 14.034C41.8009 14.1612 42.3608 14.3168 42.9121 14.5003C43.6227 14.7417 44.2844 15.0228 44.8926 15.352C45.6094 10.6999 44.8875 7.53615 42.4147 4.67026C39.6916 1.51489 34.7736 0.162109 28.4801 0.162109H10.2129C8.92677 0.162109 7.83263 1.11456 7.63107 2.40595L0.0255968 51.4705C-0.125575 52.4396 0.610948 53.3146 1.57345 53.3146H12.8516L18.7272 15.4132C18.7849 15.0407 18.9308 14.688 19.1523 14.3855C19.3737 14.0831 19.6641 13.8398 19.9984 13.6768Z'
                          fill='#27346A'
                        />
                        <path
                          d='M44.7066 16.4407C42.2988 29.0208 34.0562 33.372 23.529 33.372H18.1689C16.883 33.372 15.7968 34.3247 15.597 35.6162L12.0738 58.3358C11.9421 59.1825 12.5859 59.9498 13.4281 59.9498H22.9339C23.4785 59.9497 24.0051 59.7519 24.419 59.3921C24.833 59.0323 25.1072 58.534 25.1923 57.9869L25.2849 57.4942L27.0765 45.944L27.1919 45.3057C27.277 44.7586 27.5512 44.2604 27.9651 43.9005C28.3791 43.5407 28.9056 43.3429 29.4501 43.3427H30.8728C40.0812 43.3427 47.2915 39.5373 49.3986 28.5315C50.2781 23.9321 49.823 20.0921 47.4964 17.3949C46.7908 16.578 45.9144 15.9033 44.8935 15.3525C44.8382 15.7082 44.7783 16.0686 44.7066 16.4407Z'
                          fill='#2790C3'
                        />
                        <path
                          d='M42.3728 14.3301C41.9974 14.2186 41.6184 14.1199 41.2365 14.0341C40.8366 13.9447 40.4343 13.8669 40.0301 13.8009C38.5832 13.5627 37.0027 13.4502 35.3055 13.4502H20.9873C20.6456 13.4494 20.3082 13.5275 20.0004 13.6785C19.6657 13.841 19.375 14.0841 19.1534 14.3866C18.9319 14.6892 18.7862 15.0421 18.7289 15.4149L15.6855 35.0436L15.5977 35.6158C15.7979 34.3242 16.8838 33.3716 18.1699 33.3716H23.5301C34.057 33.3716 42.2996 29.0222 44.7074 16.4403C44.7791 16.0681 44.8393 15.7076 44.8945 15.3521C44.2848 15.0247 43.6248 14.7418 42.9141 14.502C42.7347 14.4415 42.5544 14.3842 42.373 14.3301'
                          fill='#1F264F'
                        />
                      </svg>
                      Paypal *********4556
                    </button>
                    <button className='payment_card'>
                      <svg
                        width='74'
                        height='24'
                        viewBox='0 0 74 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M28.0953 0.47433L18.4075 23.587H12.0867L7.31983 5.13941C7.03 4.00474 6.78025 3.58849 5.89842 3.11058C4.46158 2.33049 2.08742 1.59975 0 1.14341L0.141833 0.47433H10.3168C10.9817 0.473663 11.625 0.710712 12.1305 1.14269C12.636 1.57466 12.9704 2.1731 13.0733 2.83L15.5924 16.2055L21.8146 0.47433H28.0953ZM52.8638 16.0421C52.8884 9.94016 44.4277 9.60408 44.4863 6.87841C44.5048 6.049 45.2942 5.16716 47.0208 4.94208C49.0444 4.7499 51.0823 5.10783 52.9193 5.97808L53.9676 1.07558C52.1791 0.403243 50.285 0.0555394 48.3744 0.0488281C42.4637 0.0488281 38.3042 3.19383 38.2673 7.69241C38.2303 11.0193 41.2365 12.8724 43.5028 13.9824C45.8337 15.114 46.6169 15.8417 46.6046 16.853C46.5892 18.407 44.7484 19.0884 43.0279 19.1162C40.0217 19.1624 38.2796 18.3052 36.8859 17.6577L35.8037 22.7206C37.2004 23.3619 39.7781 23.9231 42.4513 23.9477C48.7321 23.9477 52.8422 20.8459 52.8638 16.0421ZM68.4685 23.587H74L69.1746 0.47433H64.0686C63.523 0.469274 62.9885 0.628245 62.5344 0.930627C62.0803 1.23301 61.7275 1.66485 61.5217 2.17016L52.5523 23.587H58.83L60.0788 20.1337H67.7501L68.4685 23.587ZM61.7992 15.3977L64.9442 6.71808L66.7572 15.3977H61.7992ZM36.6393 0.47433L31.6967 23.587H25.715L30.6637 0.47433H36.6393Z'
                          fill='#4FC8E9'
                        />
                      </svg>
                      Visa Card *********4556
                    </button>
                  </div>
                </div>
                <div className='billing_history'>
                  <div className='billing_history_header'>
                    <h6>Billing History</h6>
                    <span>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9 11.25L12 8.25M9 11.25V2.25V11.25ZM9 11.25L6 8.25L9 11.25Z'
                          stroke='#FF2552'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M1.5 12.75L1.96575 14.6138C2.04686 14.9383 2.23412 15.2263 2.49775 15.4322C2.76138 15.6381 3.08626 15.7499 3.42075 15.75H14.5792C14.9137 15.7499 15.2386 15.6381 15.5023 15.4322C15.7659 15.2263 15.9531 14.9383 16.0342 14.6138L16.5 12.75'
                          stroke='#FF2552'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                      Download All
                    </span>
                  </div>
                  <div className='billing_table'>
                    <table>
                      <thead>
                        <td>Invoice</td>
                        <td>Amount</td>
                        <td>Date</td>
                        <td>Status</td>
                        <td className='table_actions'></td>
                      </thead>
                      <tbody>
                        {couponsData.map((coupon) => (
                          <tr key={coupon.id}>
                            <td>{coupon.invoice}</td>
                            <td>{coupon.amount}</td>
                            <td>{coupon.date}</td>
                            <td>{coupon.status}</td>
                            <td className='table_actions'>
                              <svg
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M9 11.25L12 8.25M9 11.25V2.25V11.25ZM9 11.25L6 8.25L9 11.25Z'
                                  stroke='#FF2552'
                                  stroke-width='1.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                />
                                <path
                                  d='M1.5 12.75L1.96575 14.6138C2.04686 14.9383 2.23412 15.2263 2.49775 15.4322C2.76138 15.6381 3.08626 15.7499 3.42075 15.75H14.5792C14.9137 15.7499 15.2386 15.6381 15.5023 15.4322C15.7659 15.2263 15.9531 14.9383 16.0342 14.6138L16.5 12.75'
                                  stroke='#FF2552'
                                  stroke-width='1.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                />
                              </svg>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
