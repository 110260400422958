import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { get_car_by_id } from '../../../API/index';

import { format, intervalToDuration } from 'date-fns';

export default function CarCard({
  id,
  key,
  img,
  title,
  similarTo,
  type,
  noOfPeople,
  noOfBags,
  dailyRate,
  totalCost,
  cardPath,
  selectPath,
  fromDate,
  untilDate,
}) {
  const [carDetail, setCarDetail] = useState(null);

  const [carCurrentPrice, setCarCurrentPrice] = useState(dailyRate);
  const [carCheckoutPrice, setCarCheckoutPrice] = useState(null);

  const today = format(new Date(), 'yyyy-MM-dd');
  const getInterval = (fromDate, untilDate) => {
    return intervalToDuration({
      start: fromDate,
      end: untilDate,
    }).days;
  };
  useEffect(() => {
    get_car_by_id(id).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        setCarDetail(res.data);
      } else {
        console.log(res);
      }
    });
  }, []);
  // Setting Car Current Price
  useEffect(() => {
    const getCarPrice = () => {
      const basePrice = carDetail?.daily_price;
      // return carDetail?.custom_prices.forEach((customPrice) => {
      return carDetail?.custom_prices.every((customPrice) => {
        if (today >= customPrice.start_date && today <= customPrice.end_date) {
          setCarCurrentPrice(customPrice.price);
          return false;
        } else {
          setCarCurrentPrice(basePrice);
          return true;
        }
      });
    };

    getCarPrice();
  }, [carDetail]);

  useEffect(() => {
    const interval = getInterval(new Date(fromDate), new Date(untilDate));
    const carUpdatedPrice =
      carCurrentPrice && carCurrentPrice * (interval === 0 ? 1 : interval);
    setCarCheckoutPrice(carUpdatedPrice);
  }, [carCurrentPrice]);

  return (
    <Link to={cardPath} key={key} className='carCard_wrapper'>
      <div className='carCard_hero_img'>
        <img src={img} alt='car_1' />
        {/* <img src={`/assets/imgs/cars/${img}.png`} alt='car_1' /> */}
      </div>
      <div className='carCard_body'>
        <div className='carCard_title'>{title}</div>
        <div className='car_details'>
          <div className='car_similar_to'>{similarTo}</div>
          <div className='price_details'>
            Features & Price Details
            <svg
              width='8'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                fill='#4FC8E9'
              />
            </svg>
          </div>
        </div>
        <div className='car_tags'>
          <span>
            <img src='/assets/imgs/automatic.png' alt='' />
            {type}
          </span>
          <span>
            <img src='/assets/imgs/people.png' alt='' />
            {noOfPeople} People
          </span>
          <span>
            <img src='/assets/imgs/bag.png' alt='' />
            {noOfBags} Bags
          </span>
        </div>

        <div className='car_costs'>
          <div className='daily_rate'>Daily Rate: CAD {carCurrentPrice}.00</div>
          <div className='total_cost'>
            Total Cost: CAD {carCheckoutPrice}.00
          </div>
        </div>

        <div className='car_select_btn'>
          <Link to={selectPath} type='submit'>
            Select
          </Link>
        </div>
      </div>
    </Link>
  );
}
