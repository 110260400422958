import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

import { ReactComponent as DailyCalender } from '../../assets/dailyCalender.svg';
import { ReactComponent as Payment } from '../../assets/payment.svg';

export default function Sidebar({ ...props }) {
  return (
    <div className='sidebar'>
      <div>
        <div className='sidebar_logo'>
          <img src='/assets/imgs/logo.png' alt='logo' />
        </div>
        <div className='sidebar_hero'>
          <div className='sidebar_links'>
            <Link
              to='/bookings'
              className={`sidebar_link ${
                props.page === 'bookings' &&
                'sidebar_link_inverted add_booking_svg'
              }`}
            >
              <DailyCalender />
              My Bookings
            </Link>
            <Link
              to='/bookings'
              className={`sidebar_link ${
                props.page === 'daily_overview' &&
                'sidebar_link_inverted add_booking_svg'
              }`}
            >
              <DailyCalender />
              View Bookings
            </Link>
            <Link
              to='/payment'
              className={`sidebar_link ${
                props.page === 'payment' &&
                'sidebar_link_inverted add_booking_svg'
              }`}
            >
              <Payment />
              Payment
            </Link>
            <Link
              to='/trip_inormation'
              className={`sidebar_link ${
                props.page === 'trip_inormation' &&
                'sidebar_link_inverted add_booking_svg'
              }`}
            >
              <Payment />
              Yout Trip
            </Link>
          </div>
        </div>
      </div>
      <footer>
        <svg
          className='menu'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14ZM12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8Z'
            fill='white'
          />
        </svg>
        <svg
          width='25'
          height='25'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.59 22L15 16.41V7H17V15.58L22 20.59L20.59 22Z'
            fill='#4FC8E9'
          />
          <path
            d='M16 2C14.135 2.00026 12.2889 2.37478 10.5712 3.10136C8.85354 3.82795 7.29911 4.89183 6 6.23V2H4V10H12V8H7.08C8.95603 5.91529 11.503 4.55353 14.2785 4.1512C17.0541 3.74888 19.8828 4.33142 22.2736 5.79764C24.6643 7.26386 26.466 9.52115 27.3657 12.1775C28.2654 14.8338 28.2063 17.7213 27.1987 20.3386C26.191 22.9559 24.2985 25.1375 21.8497 26.5047C19.401 27.8719 16.5507 28.3381 13.794 27.8226C11.0372 27.307 8.54814 25.8422 6.759 23.6824C4.96985 21.5227 3.99367 18.8045 4 16H2C2 18.7689 2.82109 21.4757 4.35943 23.778C5.89777 26.0803 8.08427 27.8747 10.6424 28.9343C13.2006 29.9939 16.0155 30.2712 18.7313 29.731C21.447 29.1908 23.9416 27.8574 25.8995 25.8995C27.8574 23.9416 29.1908 21.447 29.731 18.7313C30.2712 16.0155 29.9939 13.2006 28.9343 10.6424C27.8747 8.08427 26.0803 5.89777 23.778 4.35943C21.4757 2.82109 18.7689 2 16 2Z'
            fill='#4FC8E9'
          />
        </svg>{' '}
        <br />
        <span>History Available</span>
        <br />
        <div>Check Your Recent Updates</div>
      </footer>
    </div>
  );
}
