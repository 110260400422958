import React, { useState } from 'react';
import usePagination from '../../../hooks/usePagination';
import Pagination from '@mui/material/Pagination';
import CarCard from '../../CardsWrapper/CarCard';
import carDummyData from '../../../fixtures/carsDummyData.json';
import './index.css';

export default function CarCarousel({ fromDate, untilDate }) {
  let [page, setPage] = useState(1);
  const PER_PAGE = 3;

  const count = Math.ceil(carDummyData.length / PER_PAGE);
  const _DATA = usePagination(carDummyData, PER_PAGE);
  //   console.log(_DATA);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className='quick_look_carousel'>
      <div className='quick_look_carousel_header'>
        <div className='heading'>FOR YOUR QUICK LOOK</div>
        <Pagination
          className='quick_look_carousel_pagination'
          shape='circular'
          count={count}
          page={page}
          onChange={handlePageChange}
        />
      </div>
      <div className='quick_look_carousel_wrapper'>
        {_DATA.currentData().map((car, index) => (
          <CarCard
            id={car.id}
            key={index}
            img={car.img}
            title={car.title}
            similarTo={car.similarTo}
            type={car.type}
            noOfPeople={car.noOfPeople}
            noOfBags={car.noOfBags}
            dailyRate={car.dailyRate}
            totalCost={car.totalCost}
            cardPath={`/car/${car.id}`}
            selectPath={`/info/${car.id}`}
            fromDate={fromDate}
            untilDate={untilDate}
          />
        ))}
      </div>
      <div>
        <Pagination
          className='quick_look_carousel_pagination quick_look_carousel_pagination_mobile'
          shape='circular'
          count={count}
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}
