import axios from 'axios';

const create_car_booking = async (data) => {
  try {
    const res = await axios.post(`/cars/book-car/`, data);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_car_by_id = async (id) => {
  try {
    const res = await axios.get(`/cars/${id}`);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};
const get_all_cars = async () => {
  try {
    const res = await axios.get(`/cars/`);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

const get_csrf_token = async (config) => {
  try {
    const res = await axios.get(`/auth/csrf/`);
    return res;
  } catch (Error) {
    // const err = Error.response.data.message;
    return Error;
  }
};

export { get_csrf_token, get_all_cars, get_car_by_id, create_car_booking };
