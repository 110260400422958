import React from 'react';
import { useParams } from 'react-router-dom';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/Navbar';
import PaymentDetails from '../../Components/PaymentDetails';
import carDummyData from '../../fixtures/carsDummyData.json';
import './index.css';

export default function PaymentPage({ ...props }) {
  const { id } = useParams();
  const data = carDummyData.find((e) => e.id === id);

  //   console.log(id);
  return (
    <div>
      <NavBar />
      <div className='payment_page'>
        <FeatureToast step={5} />
        {/* PaymentPage */}
        <PaymentDetails variables={props} data={data} />
      </div>
      <Footer />
    </div>
  );
}
