import React, { Suspense, useState } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomePage from './pages/HomePage';
import FeaturedPage from './pages/FeaturedPage';
import ProductDetailPage from './pages/ProductDetailPage';
import PaymentPage from './pages/PaymentPage';
import InfoPage from './pages/InfoPage';
import Bookings from './pages/Bookings';
import Payment from './pages/Payment';
import Settings from './pages/Settings';
import TripInormation from './pages/TripInormation';
import axios from 'axios';

export default function App() {
  // Timing Card Variables
  const [airportName, setAirportName] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [untilDate, setUntilDate] = useState(null);
  const [untilTime, setUntilTime] = useState(null);
  // user Info Variables
  const [driverLicenceImg, setDriverLicenceImg] = useState();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [flightNo, setFlightNo] = useState(null);
  const [driverLicenseNo, setDriverLicenseNo] = useState(null);
  const [airlineName, setAirlineName] = useState(null);
  const [insurancePolicyNo, setInsurancePolicyNo] = useState(null);
  const [insuranceExpDate, setInsuranceExpDate] = useState(null);
  // Payment Variables
  const [cardNumber, setCardNumber] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [postalCode, setPostalCode] = useState('');

  axios.defaults.baseURL = 'https://app.rtcar-be.tk/api/';
  // axios.defaults.baseURL = 'http://rtcar-api-62b2a63cb76744bbf9948548.c.6261e579562e4f7f795e2a10.cycle.io/api/';

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/search'
          element={<FeaturedPage fromDate={fromDate} untilDate={untilDate} />}
        />
        <Route
          path='/car/:id'
          element={
            <ProductDetailPage fromDate={fromDate} untilDate={untilDate} />
          }
        />
        <Route
          path='/payment/:id'
          element={
            <PaymentPage
              airportName={airportName}
              fromDate={fromDate}
              fromTime={fromTime}
              untilDate={untilDate}
              untilTime={untilTime}
              driverLicenceImg={driverLicenceImg}
              name={name}
              email={email}
              mobileNo={mobileNo}
              flightNo={flightNo}
              driverLicenseNo={driverLicenseNo}
              airlineName={airlineName}
              insurancePolicyNo={insurancePolicyNo}
              insuranceExpDate={insuranceExpDate}
              cardNumber={cardNumber}
              cardCvv={cardCvv}
              expiryDate={expiryDate}
              expiryYear={expiryYear}
              cardHolderName={cardHolderName}
              postalCode={postalCode}
              setCardNumber={setCardNumber}
              setCardCvv={setCardCvv}
              setExpiryDate={setExpiryDate}
              setExpiryYear={setExpiryYear}
              setCardHolderName={setCardHolderName}
              setPostalCode={setPostalCode}
            />
          }
        />
        <Route
          path='/info/:id'
          element={
            <InfoPage
              driverLicenceImg={driverLicenceImg}
              name={name}
              email={email}
              mobileNo={mobileNo}
              flightNo={flightNo}
              driverLicenseNo={driverLicenseNo}
              airlineName={airlineName}
              insurancePolicyNo={insurancePolicyNo}
              insuranceExpDate={insuranceExpDate}
              setDriverLicenceImg={setDriverLicenceImg}
              setName={setName}
              setEmail={setEmail}
              setMobileNo={setMobileNo}
              setFlightNo={setFlightNo}
              setDriverLicenseNo={setDriverLicenseNo}
              setAirlineName={setAirlineName}
              setInsurancePolicyNo={setInsurancePolicyNo}
              setInsuranceExpDate={setInsuranceExpDate}
            />
          }
        />
        <Route path='/bookings' element={<Bookings />} />
        <Route
          path='/payment'
          element={
            <Payment
              cardNumber={cardNumber}
              cardCvv={cardCvv}
              expiryDate={expiryDate}
              expiryYear={expiryYear}
              cardHolderName={cardHolderName}
              postalCode={postalCode}
              setCardNumber={setCardNumber}
              setCardCvv={setCardCvv}
              setExpiryDate={setExpiryDate}
              setExpiryYear={setExpiryYear}
              setCardHolderName={setCardHolderName}
              setPostalCode={setPostalCode}
            />
          }
        />
        <Route path='/settings' element={<Settings />} />
        <Route path='/trip_inormation' element={<TripInormation />} />
        <Route
          exact
          path='/'
          element={
            <HomePage
              airportName={airportName}
              setAirportName={setAirportName}
              fromDate={fromDate}
              setFromDate={setFromDate}
              fromTime={fromTime}
              setFromTime={setFromTime}
              untilDate={untilDate}
              setUntilDate={setUntilDate}
              untilTime={untilTime}
              setUntilTime={setUntilTime}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
