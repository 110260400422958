import React, { useState } from 'react';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FeaturedVehicles from '../../Components/FeatureWrapper/FeaturedVehicles';

import './index.css';
import ChooseVehicle from '../../Components/FeatureWrapper/ChooseVehicle';

export default function FeaturedPage({ fromDate, untilDate }) {
  const [mileage, setMileage] = useState(null);
  const [vehicle_type, setVehicle_type] = useState(null);
  const [no_of_passengers, setNo_of_passengers] = useState(null);
  const [model, setModel] = useState(null);
  const [car_type, setCar_type] = useState(null);
  const [size, setSize] = useState(null);
  const [sortBy, setSortBy] = useState(null);

  return (
    <div>
      <Navbar />
      <div className='featuredPage'>
        <FeatureToast step={3} />
        <div className='featuredPage_sortOptions'>
          <div className='attribute_wrapper'>
            <div className='sortOptions_mileage'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Mileage</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Mileage'
                  id='Mileage'
                  value={mileage}
                  label='Mileage'
                  onChange={(e) => setMileage(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={10}>
                    Mileage 1
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={20}>
                    Mileage 2
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={30}>
                    Mileage 3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_vehicle_type'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Vehicle Type</InputLabel>
                <Select
                  className='airport_name'
                  labelId='vehicle_type'
                  id='demo-simple-select'
                  value={vehicle_type}
                  label='Vehicle Type'
                  onChange={(e) => setVehicle_type(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={10}>
                    Vehicle Type 1
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={20}>
                    Vehicle Type 2
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={30}>
                    Vehicle Type 3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_no_of_passenger'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Number of Passengers</InputLabel>
                <Select
                  className='airport_name'
                  labelId='no_of_passengers'
                  id='demo-simple-select'
                  value={no_of_passengers}
                  label='Number of Passengers'
                  onChange={(e) => setNo_of_passengers(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={10}>
                    1
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={20}>
                    2
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={30}>
                    3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_model'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Model</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Model'
                  id='demo-simple-select'
                  value={model}
                  label='Model'
                  onChange={(e) => setModel(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={10}>
                    Model 1
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={20}>
                    Model 2
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={30}>
                    Model 3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_car_type'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Car Type</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Car_Type'
                  id='demo-simple-select'
                  value={car_type}
                  label='Car Type'
                  onChange={(e) => setCar_type(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={10}>
                    Car Type 1
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={20}>
                    Car Type 2
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={30}>
                    Car Type 3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_size'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Size</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Size'
                  id='demo-simple-select'
                  value={size}
                  label='Size'
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem className='airport_name_menu_item' value={10}>
                    Size 1
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={20}>
                    Size 2
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={30}>
                    Size 3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='sortOptions_mileage'>
            Sort By
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel id='airport_name'>Sort By</InputLabel>
              <Select
                className='airport_name'
                labelId='Select'
                id='Mileage'
                value={sortBy}
                label='Mileage'
                onChange={(e) => setSortBy(e.target.value)}
              >
                <MenuItem className='airport_name_menu_item' value={10}>
                  Featured
                </MenuItem>
                <MenuItem className='airport_name_menu_item' value={20}>
                  Top
                </MenuItem>
                <MenuItem className='airport_name_menu_item' value={30}>
                  Recommended
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <FeaturedVehicles fromDate={fromDate} untilDate={untilDate} />
        <ChooseVehicle untilDate={untilDate} fromDate={fromDate} />
      </div>
      <Footer />
    </div>
  );
}
